//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import MenuCard from "@/components/MenuCard";
export default {
  name: "Doctor",
  components: {
    MenuCard
  },
  computed: {
    ...mapState({
      isDoctorAccountExist: state => state.substrate.isDoctorAccountExist
    })
  }
};