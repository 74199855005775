//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "EnabledMenuCard",
  props: {
    height: Number,
    to: {
      type: [String, Object],
      default: ""
    }
  },
  methods: {
    onClick() {
      this.$emit("click");
    }
  }
};