//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EnabledMenuCard from "./EnabledMenuCard";
import DisabledMenuCard from "./DisabledMenuCard";
export default {
  name: "MenuCard",
  props: {
    icon: String,
    title: String,
    subTitle: String,
    to: {
      type: [String, Object],
      default: ""
    },
    hoverText: String,
    disabled: Boolean,
    loading: Boolean,
    height: Number
  },
  components: {
    EnabledMenuCard,
    DisabledMenuCard
  },
  computed: {
    cardComponent() {
      return this.disabled ? "DisabledMenuCard" : "EnabledMenuCard";
    },
    _icon() {
      return this.icon && (this.icon.startsWith("mdi") || this.icon.startsWith("$"));
    }
  },
  methods: {
    onClick() {
      this.$emit("click");
    }
  }
};