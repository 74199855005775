//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "DisabledMenuCard",
  props: {
    height: Number
  }
};