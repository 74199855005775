import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.cardComponent,{tag:"component",attrs:{"to":_vm.to,"height":_vm.height},nativeOn:{"click":function($event){return _vm.onClick($event)}}},[(_vm.loading)?_c(VProgressLinear,{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c(VTooltip,{attrs:{"top":"","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex flex-column justify-space-around fill-height pr-4 py-2"},'div',attrs,false),on),[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"my-3 ml-5"},[(_vm._icon)?_c(VIcon,{attrs:{"color":"#BA8DBB","size":48}},[_vm._v(" "+_vm._s(_vm.icon)+" ")]):_c(VAvatar,[_c('img',{attrs:{"src":_vm.icon}})])],1),_c('div',{staticClass:"ml-5",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"text-h6"},[_c('b',[_vm._v(_vm._s(_vm.title))])]),_c('div',{staticClass:"text-caption grey--text text--darken-1",staticStyle:{"max-height":"60px","overflow":"hidden","display":"-webkit-box","-webkit-line-clamp":"3","-webkit-box-orient":"vertical"}},[_vm._v(" "+_vm._s(_vm.subTitle)+" ")])])]),(_vm.$slots.footer)?_c('div',{staticStyle:{"text-align":"end"}},[_vm._t("footer")],2):_vm._e()])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.hoverText ? _vm.hoverText : _vm.subTitle)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }